//FIRST SECTION / HEADER
body section:first-child{
	padding-top:150px!important;
	position: relative;
}

section{
	scroll-margin-top: 170px;
}

//DIAGONALS
.diagonal-box-bottom {
	position: relative;
	margin-bottom: calc(var(--tan-21) * var(--full-width));

	&::after{
		content:'';
		position:absolute;
		z-index: 1;
		bottom: calc(((var(--tan-21) * var(--full-width)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * var(--full-width));
		background-color: inherit;

		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
	}
}

.diagonal-box-top{
	position: relative;
	margin-top: calc(var(--tan-21) * var(--full-width));

	&::before{
		content:'';
		position:absolute;
		z-index: 1;
		top: calc(((var(--tan-21) * var(--full-width)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * var(--full-width));
		background-color: inherit;

		clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
	}

	.container{
		margin-top:-15vw;
	}
}


.diagonal-box-top-bottom {
	position: relative;

	margin-top: calc(var(--tan-21) * var(--full-width));
	margin-bottom: calc(var(--tan-21) * var(--full-width));

	&::after{
		content:'';
		position:absolute;
		z-index: 1;
		bottom: calc(((var(--tan-21) * var(--full-width)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * var(--full-width));
		background-color: inherit;

		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
	}

	&::before{
		content:'';
		position:absolute;
		z-index: 1;
		top: calc(((var(--tan-21) * var(--full-width)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * var(--full-width));
		background-color: inherit;

		clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
	}

	.container{
		margin-top:-15vw;
	}
}

// TOP
body section.container-fluid.section-mt-0 {
	margin-top: 0 !important;
}
body section.container-fluid.section-mt-50 {
	margin-top: 50px !important;
}
body section.container-fluid.section-mt-100 {
	margin-top: 100px !important;
}
body section.container-fluid.section-mt-150 {
	margin-top: 150px !important;
}
body section.container-fluid.section-mt-200 {
	margin-top: 200px !important;
}
body section.container-fluid.section-mt-250 {
	margin-top: 250px !important;
}
body section.container-fluid.section-mt-300 {
	margin-top: 300px !important;
}
// TOP NEGATIVE
body section.container-fluid.section-mt--0 {
	margin-top: 0 !important;
}
body section.container-fluid.section-mt--50 {
	margin-top: -50px !important;
}
body section.container-fluid.section-mt--100 {
	margin-top: -100px !important;
}
body section.container-fluid.section-mt--150 {
	margin-top: -150px !important;
}
body section.container-fluid.section-mt--200 {
	margin-top: -200px !important;
}
body section.container-fluid.section-mt--250 {
	margin-top: -250px !important;
}
body section.container-fluid.section-mt--300 {
	margin-top: -300px !important;
}
// TOP PADDING
body section.container-fluid.section-pt-0 {
	padding-top: 0 !important;
}
body section.container-fluid.section-pt-50 {
	padding-top: 50px !important;
}
body section.container-fluid.section-pt-100 {
	padding-top: 100px !important;
}
body section.container-fluid.section-pt-150 {
	padding-top: 150px !important;
}
body section.container-fluid.section-pt-200 {
	padding-top: 200px !important;
}
body section.container-fluid.section-pt-250 {
	padding-top: 250px !important;
}
body section.container-fluid.section-pt-300 {
	padding-top: 300px !important;
}

// BOT
body section.container-fluid.section-mb-0 {
	margin-bottom: 0 !important;
}
body section.container-fluid.section-mb-50 {
	margin-bottom: 50px !important;
}
body section.container-fluid.section-mb-100 {
	margin-bottom: 100px !important;
}
body section.container-fluid.section-mb-150 {
	margin-bottom: 150px !important;
}
body section.container-fluid.section-mb-200 {
	margin-bottom: 200px !important;
}
body section.container-fluid.section-mb-250 {
	margin-bottom: 250px !important;
}
body section.container-fluid.section-mb-300 {
	margin-bottom: 300px !important;
}
// BOT NEGATIVE
body section.container-fluid.section-mb--0 {
	margin-bottom: 0 !important;
}
body section.container-fluid.section-mb--50 {
	margin-bottom: -50px !important;
}
body section.container-fluid.section-mb--100 {
	margin-bottom: -100px !important;
}
body section.container-fluid.section-mb--150 {
	margin-bottom: -150px !important;
}
body section.container-fluid.section-mb--200 {
	margin-bottom: -200px !important;
}
body section.container-fluid.section-mb--250 {
	margin-bottom: -250px !important;
}
body section.container-fluid.section-mb--300 {
	margin-bottom: -300px !important;
}
// BOT PADDING
body section.container-fluid.section-pb-0 {
	padding-bottom: 0 !important;
}
body section.container-fluid.section-pb-50 {
	padding-bottom: 50px !important;
}
body section.container-fluid.section-pb-100 {
	padding-bottom: 100px !important;
}
body section.container-fluid.section-pb-150 {
	padding-bottom: 150px !important;
}
body section.container-fluid.section-pb-200 {
	padding-bottom: 200px !important;
}
body section.container-fluid.section-pb-250 {
	padding-bottom: 250px !important;
}
body section.container-fluid.section-pb-300 {
	padding-bottom: 300px !important;
}