.icon-svg{
	display:block;
	width:32px;
	height:32px;
	background-position: center center;
	background-size: cover;
	background-color: var(--allure-yellow); 
}

.icon-svg-facebook{
	-webkit-mask-image: url('../img/sm/facebook.svg');
    mask-image: url('../img/sm/facebook.svg');	
}
.icon-svg-pinterest{
	-webkit-mask-image: url('../img/sm/pinterest.svg');
    mask-image: url('../img/sm/pinterest.svg');	
}
.icon-svg-youtube{
	-webkit-mask-image: url('../img/sm/youtube.svg');
    mask-image: url('../img/sm/youtube.svg');
}
.icon-svg-twitter{
	-webkit-mask-image: url('../img/sm/twitter.svg');
    mask-image: url('../img/sm/twitter.svg');
}
.icon-svg-instagram{
	-webkit-mask-image: url('../img/sm/instagram.svg');
    mask-image: url('../img/sm/instagram.svg');
}
.icon-svg-linkedin{
	-webkit-mask-image: url('../img/sm/linkedin.svg');
    mask-image: url('../img/sm/linkedin.svg');
}
.icon-svg-whatsapp{
	-webkit-mask-image: url('../img/sm/whatsapp.svg');
    mask-image: url('../img/sm/whatsapp.svg');
}
