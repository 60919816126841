.quick-editor {
	// Fix menus being weird
	.mce-menu, .mce-floatpanel {
		position: fixed !important;
	}

	#vue-quickeditor {
		min-height: 50vh;
	}
	.quick-editor-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		z-index: 9998;
		&::before, &::after {
			position: absolute;
			z-index: 9999;
			opacity: 0.7;
		}
		&::before {
			content: "";
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			border: 8px solid $blue;
		}
		&::after {
			content: "Quick Editor";
			bottom: 8px;
			left: 8px;
			height: 40px;
			width: 160px;
			background-color: $blue;
			color: #fff;
			font-weight: 800;
			text-align: center;
			font-size: 1.5rem;
			line-height: 40px;
		}
	}

	// Add section buttonses
	.quick-add-section {
		position: fixed;
		bottom: 8px;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-color: rgba($blue, 0.7);
		width: 600px;
		z-index: 9999;
		height: 40px;
		.btn {
			margin-top: 5px;
		}
		.form-group {
			margin-top: 5px;
			label {
				font-size: 1rem;
			}
			.floating-label {
				padding-top: 0;
			}
			input {
				background-color: rgba(#fff, 0.5);
				border-radius: 50px;
				outline: 0;
				border: 0;
				padding: 7px 10px;
				&:focus, &:hover, &:active {
					border: 0;
					box-shadow: none;
					background-color: #fff;
				}
			}
		}
	}

	// Editable stuff hovering
	.quick-editable {
		position: relative;
		min-height: 90px;
		&::before {
			transition: $transition;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			opacity: 0;
			z-index: 9999;
			border-radius: 2px;
		}
		&.ghost {
			&::before {
				opacity: .5;
				box-shadow: inset 0 0 0 5px rgba($wizardgreen, 0.5);
				background-color: rgba($blue, 0.2);
			}
			> .quick-edit, > .quick-drag {
				opacity: .5;
			}
		}
	}
	&.hover-all {
		.quick-editable::before {
			opacity: 1;
			box-shadow: inset 0 0 0 5px rgba($wizardgreen, 0.5);
		}
		.quick-editable > .quick-edit, .quick-editable > .quick-drag, .quick-add {
			opacity: 1;
		}
	}
	.hover {
		&::before {
			opacity: 1;
			box-shadow: inset 0 0 0 5px rgba($blue, 0.5) !important;
		}
		> .quick-edit, > .quick-drag, .quick-add {
			opacity: 1;
			z-index: 1000;
		}
	}

	// Cake debug
	iframe[height="40"] {
		bottom: 8px !important;
		right: 8px !important;
	}

	// Quick edit buttons
	.quick-edit, .quick-drag, .quick-add {
		z-index: 999;
		pointer-events: auto;
		transition: $transition;
		position: absolute;
		height: 40px;
		background-color: rgba($blue, 0.5);
		opacity: 0;
		.material-icons {
			color: #fff;
			width: 40px;
			height: 40px;
			display: block;
			text-align: center;
			line-height: 40px;
			font-size: 2rem;
		}
	}
	.quick-edit {
		right: 5px;
		top: 5px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 80px;
	}
	section > .quick-edit {
		width: 120px;
	}
	.col-md-1 .quick-edit {
		top: 45px;
	}

	.quick-drag {
		width: 40px;
		top: 5px;
		left: 5px;
	}
	.quick-add {
		pointer-events: none;
		background-color: transparent;
		bottom: 5px;
		left: 0;
		right: 0;
		margin: 0 auto;
		a {
			width: 100px;
			background-color: rgba($blue, 0.5);
			display: block;
			margin: 0 auto;
			pointer-events: auto;
		}
		.material-icons {
			margin: 0 auto;
		}
	}

	.content-drag-zone {
		height: 100%;
		min-height: 100px;
	}
	.content-drag-zone > span, .section-drag-zone > span {
		display: block;
		width: 100%;
		height: 100%;
		min-height: 100px;
	}
	// Drag zones
	&.show-section-drag-zones {
		.section-drag-zone {
			background-color: rgba($orange, 0.25);
		}
	}
	&.show-content-drag-zones {
		.content-drag-zone {
			background-color: rgba($orange, 0.25);
		}
	}
	&.show-section-drag-zones, &.show-content-drag-zones {
		// Prevent random sections from showing the hover stuff if dragging
		.hover {
			&::before {
				opacity: 0;
				box-shadow: none;
			}
			> .quick-edit, > .quick-drag, .quick-add {
				opacity: 0;
				z-index: 999;
			}
		}
	}

	// Show spacers
	&.show-spacers {
		.spacer, .mspacer, .dspacer {
			opacity: 1;
		}
	}

	// Show hyphens
	.hyphen {
		transition: $transition;
	}
	&.show-hyphens {
		.hyphen {
			background-color: #0084ff66;
			min-width: 20px;
			display: inline-block;
			position: relative;
			&::before {
				content: "-";
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	// Space evenly
	section.container-fluid {
		transition: margin 0.4s cubic-bezier(.3, 1, .3, 1);
	}
	// Ensure priority with this ID selector
	&.space-evenly #vue-quickeditor {
		section.container-fluid.py-0 {
			margin: 50px auto !important;
		}
	}

	h5.card-title {
		margin-bottom: 1rem;
	}

	// Random stuff to align style more with the admin version
	.modal {
		.list-group-flush .list-group-item {
			border-top: 0;
			border-bottom: 1px solid #e1e1e1;
			margin-bottom: 0;
		}
		.list-group-item {
			&.active, &:hover, &:active, &:focus {
				background-color: #eee;
			}
		}
		.form-group label {
			font-size: 1rem;
		}
		.floating-label .mce-tinymce {
			margin-top: 2rem;
		}
		.force-show-input .custom-control-input {
			opacity: 1 !important;
			z-index: 1 !important;
		}
		.date .custom-control-label {
			&::before, &::after {
				display: none;
			}
		}
	}
	.modal-content::before {
		content: "";
		position: absolute;
		top: -10%;
		left: -10%;
		width: 120%;
		height: 120%;
		background-color: transparent;
		display: block;
		z-index: -1;
	}
	#preview-options-background-video.admin-preview-filemanager-image {
		background-image: none !important;
		width: 250px;
		height: auto;
		img, .remove-admin-preview-filemanager-image {
			display: none;
		}
	}
	.inset-shadow {
		box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15) !important;
		border-radius: 2px;
	}
	.spacer, .mspacer, .dspacer {
		transition: $transition;
		background-color: #ea00ff66;
		position: relative;
		padding: 10px;
		margin: 5px;
		opacity: 0;
		&::before {
			content: "Invisible spacer";
			position: absolute;
			top: 0;
			left: 5px;
		}
	}
	.mspacer {
		background-color: #9900ff3d;
		&::before {
			content: "Invisible mobile spacer";
		}
	}
	.dspacer {
		background-color: #5900ff3d;
		&::before {
			content: "Invisible desktop spacer";
		}
	}
	span[style*="color: #ffffff;"] {
		text-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
	}
	.bg-orange-transparent {
		background-color: #ee7e1cb3;
		border-radius: 12px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	}
	.img-shadow {
		border-radius: 12px;
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
	}
	.img-circle {
		clip-path: circle();
	}
	.img-circle-shadow {
		filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
	}
	.image-switcher img {
		border-radius: 50% !important;
	}

	// Copied from material-admin.css
	.modal-footer {
		padding: 0.5rem;
		.btn-light {
			background-color: #f5f5f5;
			color: rgba(0, 0, 0, 0.87);
			&:hover {
				color: rgba(0, 0, 0, 0.87);
			}
		}
	}
	.modal-header {
		position: relative;
		border-bottom: 1px solid #e5e5e5;
		min-height: 68px;
		.btn-group {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: auto;
			.material-icons {
				font-size: 1.714286em;
				line-height: 0.583333em;
				vertical-align: -0.302198em;
			}
			button, a {
				display: inline-block;
				height: 100%;
				width: 60px;
				margin: 0;
				opacity: 1;
				background: none;
				border: none;
				color: #72777c;
				border-left: 1px solid #e5e5e5;

				text-align: center;
				font-size: 21px;
				line-height: 68px;
			}
			.close {
				padding: 0;
			}
		}
	}
	.modal-body {
		padding: 1.25rem 1.5rem;
	}

	/* moduleModal */
	#moduleContentModal {
		margin: 0 auto !important;
		width: 100%;
		max-width: 80vw;
		height: 93vh;
		min-height: 0;
		padding: 30px;
		margin: 0px;
		.modal-content {
			width: 100%;
			height: 100%;
		}
		.modal-header {
			position: relative;
			border-bottom: 1px solid #e5e5e5;
			min-height: 68px;
			.btn-group {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: auto;
				button, a {
					display: inline-block;
					height: 100%;
					width: 60px;
					margin: 0;
					opacity: 1;
					background: none;
					border: none;
					color: #72777c;
					border-left: 1px solid #e5e5e5;

					text-align: center;
					font-size: 21px;
					line-height: 68px;
				}

			}
		}
		.modal-dialog {
			overflow-y: initial !important;
			.scrollable {
				overflow-y: auto;
			}
		}
		.modal-body {
			height: calc(100% - 56px - 65px);
			padding: 0px;
			.row:first-child, .row:first-child .col:first-child {
				height: 100%;
				padding: 0px;
				margin: 0px;
			}
			#area-modulecontenttypes {
				position: relative;
				box-shadow: inset 0 4px 4px -4px rgba(0, 0, 0, .1);
				border-bottom: 0;
				border-left: 1px solid #ddd;
				background: #f3f3f3;
				padding: 15px;
				height: 100%;
			}
			#area-modulecontenttype-add {
				position: relative;
				padding: 15px;
				height: 100%;
			}
		}
	}
	/* modale edit */
	#moduleContentModalEdit .modal-body {
		max-height: 65vh;
		#previewContainer {
			max-height: 350px;
		}
	}
	#moduleContentModalEdit .modal-body #moduleContentModal .modal-body #previewContainer img, #moduleContentModalEdit .modal-body #previewContainer img {
		max-height: 350px;
	}
	#moduleContentModal .modal-body #previewContainer .embed-responsive, #moduleContentModalEdit .modal-body #previewContainer .embed-responsive {
		height: 350px;
	}
}