/* globals */
html, body {
	font-family: 'OpenSans', sans-serif;
	font-weight: 300;
	line-height: 1.8;
	font-size: 1rem;
}
strong, b {
	font-family: 'OpenSans', sans-serif;
	font-style: normal;
  	font-weight: 800;
}
p {
	font-family: 'OpenSans', sans-serif;
	line-height: 1.6;
	font-size: 1.1rem;
}
a {
	outline: none;
}
a:hover {
	text-decoration: none;
}
a.dots::before {
	content: "";
	margin: 0 10px -5px 5px;
	display: inline-block;
	background-image: url('../../img/arrow-dots@2x.png');
	background-repeat: no-repeat;
	background-size: cover;
	width: 18.5px;
	height: 25px;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0, 0, 0, 0);
}

.pos-abs {
	height: 100%;
	position: absolute;
	width: 100%;
	top: 0;
	right: 0;
	left: 0;
	margin: auto;
}

.text-black {
	color: #333 !important;
}
.text-white {
	color: #fff !important;
}
.text-orange {
	color: #ee7e1c !important;
}
.text-grey {
	color: #eeeeee !important;
}

.text-italic {
	font-style: italic;
}
section {
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
}
section.breadcrumbs {
	position: relative;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid #eee;
}
/*
section.breadcrumbs::after{
	content:'';
	position:absolute;
	width:125px;
	height:125px;
	top: -125px;
	right: 25px;
    z-index: 10;
	background-image:url('../img/tekeningen/bootje.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

@media(min-width:576px){
	section.breadcrumbs::after{
		width:150px;
		height:150px;
		top: -100px;
	    right: 50px;
	}
}
@media(min-width:768px){
	section.breadcrumbs::after{
		width:200px;
		height:200px;
		top: -125px;
	    right: 100px;
	}
}
*/
section.breadcrumbs .breadcrumb {
	background-color: transparent;
	margin-bottom: 0 !important;
}
section.p-header {
	margin-top: 150px;
}

section.bg-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 350px;
}
@media(min-width:768px) {
	section.bg-image {
		background-attachment: fixed !important;
	}
}
section.bg-image.black-overlay {
	padding-top: 100px;
	min-height: inherit;
}
section.bg-image.black-overlay h1,
section.bg-image.black-overlay h2,
section.bg-image.black-overlay h3,
section.bg-image.black-overlay h4,
section.bg-image.black-overlay h5,
section.bg-image.black-overlay h6,
section.bg-image.black-overlay .text-muted {
	color: #fff !important;
}
section.bg-image.black-overlay::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	/*background-color:rgba(84,162,216,.75);*/
	background-color: #ffba5365;
}

section .modulecontent img.img-fluid:not(.img-thumbnail) {
	margin: 0;
	padding: 5px;
}
@media(min-width:768px) {
	section .modulecontent img.img-fluid:not(.img-thumbnail) {
		padding: 15px;
		margin: 0;
	}
}

section .modulecontent img.img-fluid.img-shadow {
	margin: 5px;
	padding: 0;
}
@media(min-width:768px) {
	section .modulecontent img.img-fluid.img-shadow {
		margin: 15px;
		padding: 0;
	}
}


section p img {
	margin: 0px 15px 15px 15px;
}

section.bg-drawing-footer + section {
	padding-top: 75px;
}

.title-row {
	text-align: center;
}
.vertical-center {
	min-height: 100%;
	/* Fallback for browsers do NOT support vh unit */
	min-height: 100vh;
	/* These two lines are counted as one :-)       */

	display: flex;
	align-items: center;
}

/* lazy images */
/* fade image in after load */
.lazyload,
.lazyloading {
	opacity: 0;
	display: block;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}

.align-center {
	text-align: center;
}

.message {
	display: block;
	width: 100%;
	padding: 15px;
	margin-bottom: 15px;
}

.message.success {
	color: #3faf6c;
}
.message.error {
	color: #ec6446;
}

/* LOGIN AREA */
.popover-login {
	background-color: #ee7e1c;
	border-color: #ee7e1c;
	border-radius: 0px;
}
.popover-login .arrow::before, .popover-login .arrow::after {
	border-bottom-color: #ee7e1c;
}
.popover-login h5 {
	color: #fff;
}
.popover hr {
	border-color: #fff;
}


.pagination li a {
	color: #ee7e1c;
	height: 100%;
	line-height: 46px;
	padding: 0 0.75rem;
}
.pagination .page-link .material-icons {
	vertical-align: -6px;
}
.pagination .page-item.active .page-link {
	background-color: #ee7e1c;
	border-color: #ee7e1c;
}
/* HEADER */
header.header-home {
	overflow: hidden;
	position: relative;
	padding-top: 30% !important;
	/* slope */
	height: 200px !important;
	/* start height */
	background: #ee7e1c;
	border-bottom: 8px solid #ee7e1c;
}


@media(min-width:576px) {
	header.header-home {
		height: 250px !important;
		/* start height */
	}
}
@media(min-width:768px) {
	header.header-home .swiper-container .row.h-100 {
		background-color: rgba(255, 255, 255, 0);
	}
}


header.header-home .swiper-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

header.header-home .swiper-container .row.h-100 {
	background-color: rgba(255, 255, 255, .85);
}

/* pagination */
header.header-home .swiper-container-horizontal > .swiper-pagination-bullets,
header.header-home .swiper-pagination-custom, header.header-home .swiper-pagination-fraction {
	bottom: 40px !important;
}
.swiper-pagination-bullet-active {
	background: #ee7e1c !important;
}


header.img-header {
	position: relative;
	background: #ee7e1c;
	border-bottom: 8px solid #ee7e1c;
}

@media(min-width:1500px) {
	header.header-home {
		padding-top: 0% !important;
		/* slope */
		height: 300px !important;
		/* start height */
	}
	header.header-home .swiper-container {
		position: absolute;
		/* width: 1500px; */
		height: 300px;
		top: 0;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}
}


/* SLIDE */
.swiper-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: transparent;
}
.swiper-slide {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: transparent;
}
.slider-overlay {
	display: table;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
}

@media(max-width:768px) {
	.swiper-slide h1 span, .swiper-slide h2 span, .swiper-slide h3 span, .swiper-slide h4 span, .swiper-slide h5 span, .swiper-slide h6 span {
		color: #ee7e1c !important;
	}
	.swiper-slide p span {
		color: #333 !important;
	}
	.swiper-slide .btn-outline-primary {
		color: #ee7e1c !important;
		background-color: #fff !important;
	}
}

header .btn {
	width: fit-content;
}

.btn-center {
	margin: 15px auto;
	display: block;
	width: fit-content;
}

.center {
	margin: 35px auto;
	text-align: center;
	display: block;
}

header.header-sm {
	min-height: 35vh;
	height: auto;
	padding: 100px 0px 50px;
}



/* HEADER BLOCKS */
section.section-header-blocks {
	position: relative;
	margin-top: 0px;
	padding-bottom: 0px;
}
@media(min-width:768px) {
	.container.elevated {
		margin-top: -280px;
	}
	section.section-header-blocks {
		z-index: 2;
	}
}
@media(min-width:1200px) {
	.container.elevated {
		margin-top: -330px;
	}
}
section.section-header-blocks h2 {
	font-size: 24px;
	color: #fff;
}
section.section-header-blocks p {
	font-size: 16px;
	color: #fff;
	margin-bottom: 0;
}
section.section-header-blocks .card {
	background-color: #00abd171;
	box-shadow: 0 0 5px #00abd171;
	text-shadow: 0 0 9px rgba(0, 0, 0, 0.35);
	border-radius: 0;
	font-weight: bold;
}
section.section-header-blocks .btn {
	font-size: 16px;
	text-shadow: initial;
	box-shadow: 0 0 9px rgba(0, 0, 0, 0.25);
}

section.section-header-blocks .card .card-body {
	min-height: inherit;
}
@media(min-width:992px) {
	section.section-header-blocks .card .card-body {
		min-height: inherit;
	}
}


section.section-header-blocks .card .card-body,
section.section-header-blocks .card .card-footer {
	background-color: transparent;
	padding: 1rem;
}
section.section-header-blocks .card .card-footer {
	border-top: none;
}

.card.test-form .card-body, .card.test-form .card-footer {
	background-color: #ee7e1c !important;
	color: #fff;
}
.card.test-form .card-body h3, .card.test-form .card-body p {
	color: #fff;
}
.card.test-form .card-footer .btn {
	color: #ee7e1c;
	border-color: #fff;
	background-color: #fff;
}
.card.test-form .card-footer .btn:hover, .card.test-form .card-footer .btn:focus {
	color: #fff;
	border-color: #fff;
	background-color: #ee7e1c;
}
.card.test-form .form-control {
	font-size: inherit;
	border: 1px solid #fff;
	background-color: #fff;
	padding: .375rem .75rem;
}

section.section-header-blocks .card.elevated .card-body {
	min-height: inherit;
}
@media(min-width:992px) {
	section.section-header-blocks .card.elevated .card-body {
		min-height: inherit;
	}
}

.elevated {
	position: relative;
	margin-top: -305px;
	z-index: 1;
	pointer-events: none;
}
.elevated a, .elevated .btn {
	pointer-events: all;
}
section.section-header-blocks .row-blocks {
	align-items: flex-start;
}
@media(min-width:992px) {
	section.section-header-blocks .row-blocks {
		align-items: flex-end;
	}
}


/* LIST PAGES */
.list-row {
	border-bottom: 1px solid #eee;
}
.list-image {
	display: block;
	float: none;
	height: 160px;
	width: 160px;
	margin-right: 25px;
}
@media(min-width:576px) {
	.list-image {
		/* display:inline-block; */
		float: left;
	}
}
.list-image a {
	display: inline-block;
	height: 150px;
	width: 150px;
	margin: 0;
	padding: 0;
}
.list-image img {
	width: 150px;
	height: 150px;
	margin: 0px !important;
}
.list-header {
	float: none;
	width: 100%;
	display: inline-block;
	vertical-align: top;
}
@media(min-width:576px) {
	.list-header {
		width: calc(100% - 185px);
		float: left;
	}
}
.list-header h3 {
	color: #ee7e1c;
}
.list-header small {
	color: #ababab;
	font-style: italic;
}


/* TEAM */
.team-image {
	display: block;
}
.team-image img {
	object-fit: cover;
	margin: 0px auto;
	width: 160px;
	height: 160px;
}

.team-links {
	display: block;
	padding: 0;
	margin: 0;
	text-align: center;
	list-style: none;
}
.team-links li {
	display: inline-block;
}
.team-links li a {
	width: 50px;
	height: 50px;
	text-align: center;
	display: block;
	border-radius: 50%;
	line-height: 50px;
	font-size: 25px;
}
.team-links li a:hover, .team-links li a:focus {
	color: #fff;
	background-color: #ee7e1c;
}



/* PARTNER */
.partner-image {
	display: block;
}
.partner-image img {
	margin: 0px auto;
	width: 160px;
	height: 160px;
}

.partner-links {
	display: block;
	padding: 0;
	margin: 0;
	text-align: left;
	list-style: none;
}
.partner-links li {
	display: inline-block;
}
.partner-links li a {
	width: 50px;
	height: 50px;
	text-align: center;
	display: block;
	border-radius: 50%;
	line-height: 50px;
	font-size: 20px;
}
.partner-links li a:hover, .partner-links li a:focus {
	color: #fff;
	background-color: #ee7e1c;
}


/* FORMS */
.form-control, .custom-select, .form-control-file {
	background-clip: padding-box;
	background-color: transparent;
	border-color: rgba(0, 0, 0, 0.42);
	border-radius: 0;
	border-style: solid;
	border-width: 0 0 1px;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.87);
	display: block;
	font-size: 1rem;
	line-height: 1.5;
	padding: 0.375rem 0 calc(0.375rem - 1px);
	width: 100%;
}
@media (-webkit-min-device-pixel-ratio: 0) {
	select.form-control, .custom-select {
		background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000000" fill-opacity="0.54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7 10l5 5 5-5z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
		background-position: 100% 50%;
		background-repeat: no-repeat;
		background-size: 1.5em 1.5em;
		padding-right: 1.5em;
	}
}
select.form-control, .custom-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.form-control:focus, .custom-select:focus, .form-control-file:focus {
	border-color: #476580;
	box-shadow: inset 0 -2px 0 -1px #476580;
	outline: 0;
}

.floating-label {
	padding-top: 0.75rem;
	position: relative;
}
.floating-label .form-control, .floating-label .custom-select, .floating-label .form-control-file {
	position: relative;
}
/* .form-group .floating-label label {
    font-size: 1.3rem;
} */
.floating-label.has-value label, .floating-label.is-focused label {
	top: 0;
	-webkit-transform: scale(0.75);
	transform: scale(0.75);
}
@media (min-width: 992px) {
	.floating-label label {
		transition-duration: 0.2s;
	}
}
@media (min-width: 576px) {
	.floating-label label {
		transition-duration: 0.39s;
	}
}
.floating-label label {
	transition-duration: 0.3s;
	transition-property: color, top, -webkit-transform;
	transition-property: color, top, transform;
	transition-property: color, top, transform, -webkit-transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	color: rgba(0, 0, 0, 0.38);
	display: block;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0;
	padding: 0;
	position: absolute;
	top: 1.125rem;
	left: 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

label:not(.form-card-radio) {
	font-size: 0.75rem;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.38);
	display: inline-block;
}

.form-group .floating-label .form-text {
	font-size: 1rem;
}
.text-muted {
	color: rgba(0, 0, 0, 0.38) !important;
}
.form-text {
	font-size: 0.75rem;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1.5;
	color: rgba(0, 0, 0, 0.38);
	display: block;
	margin-top: 0.5rem;
}
small, .small {
	font-size: 80%;
	font-weight: 400;
}
small {
	font-size: 80%;
}


/* checkboxes */
.custom-checkbox, .custom-radio {
	padding-left: 2rem;
}
.custom-checkbox label, .custom-radio label {
	color: rgba(0, 0, 0, 0.87);
}
.checkbox .custom-control-label::before {
	position: absolute;
	top: .25rem;
	left: 0;
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	pointer-events: none;
	content: "";
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: #dee2e6;
}
/* .custom-checkbox .custom-control-input:checked~.custom-control-label::after {

} */
.checkbox .custom-control-label::after {
	position: absolute;
	top: .25rem;
	left: 0;
	display: block;
	width: 1.5rem;
	height: 1.5rem;
	content: "";
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
}

.form-group.select > label, .form-group.radio > label {
	color: rgba(0, 0, 0, 0.87);
	font-size: 1rem;
}

.form-group.select .custom-checkbox label, .custom-radio label {
	color: rgba(0, 0, 0, 0.87);
	font-size: 1rem;
	margin-bottom: .5rem;
	margin-top: 0.2rem;
}


/* NEWSLETTER */
form#newsletterForm .form-control {
	border: 1px solid #eee;
	background-color: #fff;
	padding: .375rem .75rem;
}
form#newsletterForm button[type=submit] {
	border: 1px solid #fff;
	color: #fff;
	background-color: #f39200;
}
form#newsletterForm button[type=submit]:hover {
	border: 1px solid #f39200;
	color: #f39200;
	background-color: #fff;
}


/* CONTACT */
/* section.section-contact{ } */

.contact-form .form-control {
	border-radius: 1px;
	border-color: #ccc;
}
.contact-form .form-control::placeholder {
	color: #777;
	opacity: .5;
}
.contact-form .btnloaderwrapper {
	height: 43px;
	color: #ee7e1c;
	vertical-align: middle;
}

.contact-info, .contact-info a {
	color: #ee7e1c;
}

/* .container-fluid:last-of-type {
	padding-bottom:3rem!important;
} */



/* FOOTER */
footer {
	position: relative;
	font-size: 12px;
	padding-top: 50px;
}

footer p {
	font-size: inherit;
	line-height: 1.6;
}
footer a {
	color: #fff;	
}
footer a:hover {
	color: #000;
}

footer .form-control {
	font-size: inherit;
	border: 1px solid #fff;
	background-color: #fff;
	padding: .375rem .75rem;
}
footer .btn {
	font-size: inherit;
}

footer ul {
	list-style: none;
	padding: 0px;
}
footer ul ul {
	list-style: none;
	padding-left: 15px;
}

footer .footer-social img.footer-logo {
	height: 125px;
	margin-bottom: 25px;
}

footer .footer-social ul {
	list-style: none;
	padding: 0px;
	margin-top: 15px;
}
footer .footer-social ul li {
	display: inline-block;
	margin-right: 10px;
}
footer .footer-social ul li a {
	padding:5px;
	text-align: center;
	display: block;
	border-radius: 50%;	
	background-color:#fff;
}

footer .footer-social ul li a:hover {
	background-color: #000;
	color:#fff;
}

footer .footer-info img {
	height: 50px;
	margin: 0px auto;
}

footer #logo-iscreen, footer #logo-iso {
	height: 75px;
	text-align: left;
}
footer #logo-iscreen a, footer #logo-iso a {
	display: block;
}
/* footer #logo-iscreen img{
	max-width:150px;
} */
footer #logo-iso img {
	height: 75px;
}
@media(min-width:768px) {
	footer #logo-iscreen, footer #logo-iso {
		text-align: center;
	}
	footer #logo-iscreen img {
		max-width: 100%;
	}
}




footer .footer-search-menu ul {
	list-style: none;
	padding: 0;
	columns: 2;
	-webkit-columns: 2;
	-moz-columns: 2;
}

footer .nav-link {
	padding: 0;
	text-decoration: none;
}
footer .nav-link:hover {
	color: #000;	
}

.footer-subfooter {
	padding: 5px;
	padding-top: 25px;
	padding-bottom: 10px;
}

/* SEARCH */
/* .section-search{ } */
.section-search h1, section-search h2 {
	color: #ee7e1c;
}
.search-results h1 {
	color: #ee7e1c;
}


/* cards */
.card {
	border-radius: 2px;
	background-color: #fff;
	display: -ms-flexbox;
	/*display: flex;*/
	/*-ms-flex-direction: column;*/
	flex-direction: column;
	min-width: 0;
	position: relative;
	word-wrap: break-word;
	border: none;

	/* box-shadow: 0 0 4px 0 rgba(0,0,0,.14), 0 3px 4px 0 rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);  */
	box-shadow: none;
}
.shadow-none {
	box-shadow: none !important;
}
.shadow-2 {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, .14), 0 3px 4px 0 rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-4 {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .14), 0 4px 5px 0 rgba(0, 0, 0, .12), 0 1px 10px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-6 {
	box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-8 {
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 3px rgba(0, 0, 0, .12), 0 4px 15px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-12 {
	box-shadow: 0 12px 17px 2px rgba(0, 0, 0, .14), 0 5px 22px 4px rgba(0, 0, 0, .12), 0 7px 8px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-16 {
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px 0 rgba(0, 0, 0, .2) !important;
}
.shadow-24 {
	box-shadow: 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12), 0 11px 15px 0 rgba(0, 0, 0, .2) !important;
}



.card.card-hover {
	/*box-shadow: 0 3px 20px 0 rgba(0,0,0,.1);*/
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .07), 0 3px 14px 3px rgba(0, 0, 0, .06), 0 4px 15px 0 rgba(0, 0, 0, .1);
	transition: box-shadow 0.2s ease-in-out;
}
.card.card-hover:hover {
	/*box-shadow: 0 3px 20px 0 rgba(0,0,0,.5);*/
	box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .17), 0 3px 14px 3px rgba(0, 0, 0, .15), 0 4px 15px 0 rgba(0, 0, 0, .25);
}

@media(min-width:768px) {
	.card.card-hover .card-body {
		min-height: 150px;
		padding-bottom: 0px;
	}
}
@media(min-width:992px) {
	.card.card-hover .card-body {
		min-height: 190px;
		padding-bottom: 0px;
	}
}

.card.card-hover .card-body h3.card-title {
	font-size: 1.25rem !important;
}

.card-img-top {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}
/* img.card-img-top{} */
div.card-img-top {
	height: 150px;
	display: block;

	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: #eee;
	background-image: url('../../img/img-default@3x.jpg');
}

.card-body {
	flex: 1 1 auto;
	padding: 1rem;
}
.card-actions:last-child {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}
.card-actions {
	/*-ms-flex-align: start;*/
	align-items: flex-start;
	/*display: -ms-flexbox;*/
	display: flex;
	padding: 0rem 1rem 1rem;
}
.card-title {
	font-size: 1rem !important;
	font-weight: 400;
	letter-spacing: 0;
	line-height: 1.333334;
	margin-bottom: 0rem;
}
.card-text:last-child {
	margin-bottom: 0;
}
.card-body > :last-child {
	margin-bottom: 0;
}

#homeTestimonials {
	color: #333 !important;
}
/* This quote stuff took me like 45 minutes! */
.quotation {
	/* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
	/* font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
	font-family: Arial, Helvetica, sans-serif;
	position: relative;
	display: inline-block;
	color: #ee7e1c !important;
	font-size: 8rem;
	padding: 0;
	line-height: 0;
	opacity: .5;
}
.quotation.start {
	top: 4.2rem;
	right: 0.8rem;
}
.quotation.end {
	top: -2.5rem;
	left: 1.8rem;
	font-size: 1rem;
	transform: scale(8);
	margin-right: 2.8rem;
}
div.table-responsive table h1, div.table-responsive table h2, div.table-responsive table h3,
div.table-responsive table h4, div.table-responsive table h5, div.table-responsive table h6 {
	margin-bottom: 0;
}
.icon-small {
	font-size: inherit;
	line-height: inherit
}