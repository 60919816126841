$allureyellow: #ffc107;
$wizardgreen: #ee7e1c;
$orange: #ee7e1c;
$softorange: #fce5d4;
$torange: #ffba5328;
$blue: #00afff;
$primary: #476580;
$softprimary: #68849c;
$gradienttop: linear-gradient(to top, #ee7a01, #e84e10);
$gradientbot: linear-gradient(to bottom, #ee7a01, #e84e10);
$transition: all 0.4s cubic-bezier(.3, 1, .3, 1);
$transition-fast: all 0.25s cubic-bezier(.3, 1, .3, 1);
$transition-slow: all 0.6s cubic-bezier(.5, 1, .5, 1);

:root{
	--allure-yellow: #ffc107;

	--width: 960px;
  	--full-width: 100vw;
	--angle: 21.6deg;
	/*-----------------
	Magic Number Alert:

	the 0.19796 is calculated by this formula:
	tan(21.6°) / 2
	21.6° is the current skewY value.
	------------------*/
	--tan-21: 0.38386;
	--magic-number: 0.19796;
	--skew-padding: calc(var(--width) * var(--magic-number));
	--clip-padding: calc(var(--full-width) * var(--magic-number));
}

@import url('allure.css');
@import '_socialmedia_icons', 'section', 'quickeditor', 'svg-icons';

html {
	scroll-behavior: smooth;
	background-color: #000;

	scroll-padding: 170px 0px 0px 0px;
}

@media(min-width:768px){
	html {
		scroll-padding: 200px 0px 0px 0px;
	}
}
@media(min-width:992px){
	html {
		scroll-padding: 250px 0px 0px 0px;
	}
}

body {
	overflow-x: hidden;
	position: relative;
	background-color: #000;
}
main {
	overflow: hidden;
}
section {
	hyphens: auto;
}
.material-icons {
	user-select: none;
}
.container {
	position: relative;
	z-index: 5;
}
h1, h2, h3, h4, h5, h6 {
	word-break: break-word;
	line-height: 1.08;
}
.clickable {
	cursor: pointer;
}
.alert-container {
	max-width: 400px;
	transition: $transition;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
	pointer-events: none;
	display: none
}
.section-line {
	border: 0;
	height: 3px;
	background-image: linear-gradient(to right, $orange 50%, transparent 0%);
	background-position: bottom;
	background-size: 40px 20px;
	background-repeat: space;
	width: 100%;
}
hr.slab {
	background-color: $orange;
	width: 100%;
	max-width: 200px;
	border-radius: 50px;
	height: 8px;
	margin-bottom: 1.75rem;
}
//
// FOOTER
//
.footer-wrapper {
	overflow: hidden;
	position: relative;
}
.footer-menu {
	background-color: rgba(255, 255, 255, 0.2);
}
//
// BG
//
.bg-footer {
	color: #fff;
	background-color: $allureyellow;
	position: relative;
}
.bg-footer a{
	color: #fff;
}
.bg-transparent {
	background-color: transparent;
}
.bg-white {
	background-color: #fff;
}
.bg-yellow {
	background-color: $allureyellow;
	color: #fff;
}
.bg-black {
	background-color:#000;
	color: #fff;
}
.bg-header {
	background-image: url('../img/backgrounds/header_bg.svg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: bottom center;
	background-attachment: local;
	width: 100%;
	height: 100%;
	min-height: 550px;
	padding-top: 85px !important;
	padding-bottom: 250px !important;
	// overflow: hidden;
	position: relative;
}
.bg-small-header {
	min-height: 300px;
	padding-top: 113px !important;
	padding-bottom: 50px !important;
	background: $gradienttop;
	position: relative;
	overflow: hidden;
}

//BORDERS
.border-yellow{
	border: 2px solid var(--allure-yellow);
}
.border-black{
	border: 2px solid #000;
}

//
// BUTTONS & LINKS
//
section a {
	color: #333;
	transition: $transition;
	&:hover, &:focus, &:active {
		color: $allureyellow;
		background-color: #333;
		text-decoration: none;
	}
}
section.bg-black a{
	color: $allureyellow;
	transition: $transition;
	&:hover, &:focus, &:active {
		color: #333;
		background-color: $allureyellow;
		text-decoration: none;
	}
}
.arrow-link {
	&::before {
		transition: $transition;
		content: "";
		margin: 0 10px -5px 5px;
		display: inline-block;
		background-image: url('../img/mto_bolletjes@2x.png');
		background-repeat: no-repeat;
		background-size: cover;
		width: 50.25px;
		height: 25px;
	}
	&:hover, &:focus, &:active {
		&::before {
			margin: 0 5px -5px 10px;
		}
	}
}

.btn{
	border-radius:0;
}

a.btn-allure-black{
	display: inline-block;
	position: relative;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: .5rem;
	color: $allureyellow;
	background-color: #000;

	font-size: 1rem;
	font-weight: 100;
	text-align: left;

	line-height: 1;

	strong{
		font-size: 1.5rem;
		display: inline-block;
		width: 100%;;
		font-weight: 800;
		margin-bottom: .8rem;
	}

	&::before{
		content:'';
		position: absolute;
		z-index: -1;
		display: block;
		width:100%;
		height:100%;
		background-color: $allureyellow;
		border:2px solid #000;
		bottom:-5px;
		right:-5px;

		transition: all 100ms ease-in-out;
	}

	&:hover::before{
		bottom:-10px;
		right:-10px;
	}
	&:hover, &:focus {
		color: #fff;
	}
}

a.btn-allure-yellow{
	display: inline-block;
	position: relative;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: .5rem;
	color: #000!important;
	background-color: $allureyellow;

	font-size: 1rem;
	font-weight: 100;
	text-align: left;

	line-height: 1;

	strong{
		font-size: 1.5rem;
		display: inline-block;
		width: 100%;;
		font-weight: 800;
		margin-bottom: .8rem;
	}

	&::before{
		content:'';
		position: absolute;
		z-index: -1;
		display: block;
		width:100%;
		height:100%;
		background-color: #000;
		border:2px solid $allureyellow;
		bottom:-5px;
		right:-5px;

		transition: all 100ms ease-in-out;
	}

	&:hover::before{
		bottom:-10px;
		right:-10px;
	}
	&:hover, &:focus {
		color: #fff;
	}
}

form{
	z-index:0;
	position: relative;
}

button.btn-allure-black{
	display: inline-block;
	position: relative;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-top: .5rem;
	color: $allureyellow;
	background-color: #000;

	font-size: .8rem;
	font-weight: 100;
	text-align: left;

	line-height: 1;

	strong{
		font-size: 1.5rem;
		display: inline-block;
		width: 100%;;
		font-weight: 800;
		margin-bottom: .8rem;
	}

	&::before{
		content:'';
		position: absolute;
		z-index: -1;
		display: block;
		width:100%;
		height:100%;
		background-color: $allureyellow;
		border:2px solid #000;
		bottom:-5px;
		right:-5px;

		transition: all 100ms ease-in-out;
	}

	&:hover::after{
		bottom:-15px;
		right:-15px;
	}
	&:hover, &:focus {
		color: #fff;
	}
}

.btn-primary {
	background-color: $orange;
	border: 1px solid $orange;
	color: #fff;
	&:hover, &:focus, &:active {
		background-color: #fff !important;
		border: 1px solid $orange  !important;
		color: $orange  !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($orange, 0.5) !important;
	}
}
.btn-outline-primary {
	background-color: transparent;
	border: 1px solid $orange;
	color: $orange;
	&:hover, &:focus, &:active {
		background-color: $orange  !important;
		border: 1px solid #fff !important;
		color: #fff !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($orange, 0.5) !important;
	}
}
.btn-outline-primary-white {
	background-color: #fff;
	border: 1px solid $orange;
	color: $orange;
	&:hover, &:focus, &:active {
		background-color: $orange  !important;
		border: 1px solid #fff !important;
		color: #fff !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($orange, 0.5) !important;
	}
}

.btn-blue {
	background-color: $blue;
	border: 1px solid $blue;
	color: #fff;
	&:hover, &:focus, &:active {
		background-color: #fff !important;
		border: 1px solid $blue  !important;
		color: $blue  !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
	}
}
.btn-outline-blue {
	background-color: transparent;
	border: 1px solid $blue;
	color: $blue;
	&:hover, &:focus, &:active {
		background-color: $blue  !important;
		border: 1px solid #fff !important;
		color: #fff !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
	}
}
.btn-outline-blue-white {
	background-color: #fff;
	border: 1px solid $blue;
	color: $blue;
	&:hover, &:focus, &:active {
		background-color: $blue  !important;
		border: 1px solid #fff !important;
		color: #fff !important;
	}
	&:focus, &:active {
		box-shadow: 0 0 0 0.2rem rgba($blue, 0.5) !important;
	}
}
.btn:not(.btn-sm) span.material-icons {
	font-size: 2rem;
	line-height: 24px;
}
.btn-orb {
	padding: 0 !important;
	background-color: transparent;
	box-shadow: none !important;
	color: $orange  !important;
	span.material-icons {
		margin-right: 1rem;
		line-height: 38px !important;
		color: #fff !important;
		height: 38px;
		width: 38px;
		background-color: $orange;
		border-radius: 50%;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
	}
}
//
// NAVBAR
//
#main-menu.navbar.navbar-dark {
	position: fixed !important;
	padding-bottom: 0;
	background-color:transparent;
	transition-duration: .3s;
	max-height: 80vh;

	&::before {
		content:'';
		position: absolute;
		top:0;
		left:0;
		display: block;
		width:100%;
		height:60%;
		background-color: #000;
	}

	.drop-icon {
		vertical-align: text-bottom;
		font-size: 1.5rem;
		line-height: 22px;
	}
	.nav-link {
		position: relative;
		padding-left: 1rem;
		padding-right: 1rem;
		color: $allureyellow;
		background-color: #000;

		font-size: 1.5rem;
		font-weight: 800;
		text-align: left;
		line-height: 1;

		max-width:150px;

		span{
			font-size: .8rem;
			display: inline-block;
			width: 100%;;
			font-weight: 100;
			margin-bottom: .8rem;
		}

		&::before{
			content:'';
			position: absolute;
			z-index: -1;
			display: block;
			width:100%;
			height:100%;
			background-color: $allureyellow;
			border:2px solid #000;
			bottom:-5px;
			right:-5px;

			transition: all 100ms ease-in-out;
		}

		&:hover::before{
			bottom:-10px;
			right:-10px;
		}

		&:hover, &:focus {
			color: #fff;
		}
	}

	.sub-menu {
		background-color: #fff;
		.nav-link {
			color: $orange;
			text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
			&:hover, &:focus {
				color: #ffffff;
				background-color: $orange;
			}
		}
		// A sneaky box so that the submenu can stay open when the mouse is out of the menu but still close
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: -15px;
			width: calc(100% + 30px);
			height: calc(100% + 15px);
			background-color: transparent;
		}
	}

	.search-form input {
		background-color: transparent;
		border: 1px solid transparent;
	}
}

//TIE
#tie{
	position: absolute;
	z-index: 2;
	top: 325px;
    left: 0;
    display: block;
    width: 275px;
    height: 500px;
	pointer-events: none;
}




// Mobile menu
.navbar-toggler, .navbar-brand {
	margin-bottom: .5rem;
}
.navbar-toggler {
	transition: $transition;
	background-color: $allureyellow;
	color:#000;
	&:active, &:hover, &:focus {
		outline: 0;
		background-color: $allureyellow;
		color:#000;
	}
}
.navbar-brand {
	width: 150px;
	display: block;
}
@media(min-width:768px) {
	.navbar .container, .navbar .container-fluid {
		position: relative;
	}
	.navbar-brand {
		width: 350px;
		font-size: 24px;
	}
}
.navbar-nav {
	text-align: left;
	ul {
		padding: 0;
		margin: 0;
	}
	a {
		display: block;
		position: relative;
		width: 100%;
		transition: all .125s ease-in-out;
	}
	li {
		&.nav-item {
			display: block;
			position: relative;
		}
		label.drop-icon {
			cursor: pointer;
			color: #fff;
			position: absolute;
			right: 0em;
			top: 0em;
			height: 100%;
			padding: 10px;
			margin: 0px;
			background-color: $orange;
			font-size: 16px;
		}
	}
	input[type="checkbox"], span.drop-icon {
		display: none;
	}
	.sub-menu {
		display: none;
		text-align: left;
		background-color: #fff;
		box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
		min-width: 100%;
		li.nav-item {
			width: 100%;
			background-color: transparent;
			display: block;
			a.nav-link {
				width: 100%;
				display: inline-block;
				border-bottom: none;
			}
		}
	}
	input[type="checkbox"]:checked + .sub-menu {
		display: block;
	}
}

@media only screen and (min-width: 768px) {
	.navbar-nav .main-menu {
		display: block;
	}
	.navbar .navbar-collapse {
		align-self:center;
	}
	.navbar-nav {
		text-align: right;
		a {
			width: max-content;
		}
		label.drop-icon {
			display: none;
		}
		span.drop-icon {
			font-size: 1.5rem;
			line-height: 1.5rem;
			display: inline-block;
		}
		li.nav-item {
			display: inline-block;
			margin:.5rem;
		}
		.nav-link {
			padding-top: 0;
			padding-bottom: 0;
		}
		.sub-menu {
			border-width: 0;
			margin: 0;
			position: absolute;
			top: 100%;
			right: 0;
			z-index: 3000;
			li {
				float: none;
				border-width: 0 0 1px;
			}
			.sub-menu {
				top: 0;
				left: 100%;
			}
		}
		.sub-menu, input[type="checkbox"]:checked + .sub-menu {
			display: block;
			transform: scaleY(0) !important;
			transform-origin: top;
			transition: $transition-fast;
		}
		li:hover > input[type="checkbox"] + .sub-menu {
			transform: scaleY(1) !important;
		}
	}
}

.sub-navbar {
	display: block;
	li.nav-item {
		display: inline-block;
	}
	.nav-link {
		font-size: 13px;
		line-height: 32px;
		color: #fff;
		padding-right: .5rem;
		padding-left: .5rem;
		&:hover, &:focus {
			color: #ffffff77;
		}
	}
}
.navbar-expand-md .navbar-nav {
	-ms-flex-direction: row;
	flex-direction: row;
	align-items: center;
}
@media(min-width:768px) {
	.sub-navbar {
		display: flex;
		position: absolute;
		top: 0;
		right: 15px;
		li.nav-item {
			display: block;
			width: auto;
		}
	}
	.navbar-expand-md .navbar-nav {
		-ms-flex-direction: row;
		flex-direction: row;
		align-items: initial;
	}
}

.img-shadow {
	border-radius: 12px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.img-circle {
	clip-path: circle();
}
.img-circle-shadow {
	filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
}
.list-image {
	position: relative;
	a {
		filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.08));
		.img-circle {
			border: none;
		}
	}
	svg {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		g {
			transform: scale(2.5) translate(-350px, 0);
		}
	}
	&.blob-smaller svg g {
		transform: scale(1.6) translate(-275px, 25px);
	}
}


/** VOORSTELLINGEN **/
.event-wrapper{
	position:relative;

	.event-groups{
		position: absolute;
		right:10px;
		top:-22px;
		display: flex;

		.event-group{
			display: inline-flex;
			position: relative;
			align-items: center;
			margin-left:1rem;
			padding:.5rem 1rem;

			i.material-icons{
				position: absolute;
				left:-14px;
			}

			&.group-zwart{
				color:#000;
				background-color: $allureyellow;
				border: 2px solid #000;

				i.material-icons{
					color:#000;
					background-color: $allureyellow;
				}
			}

			&.group-geel{
				color:$allureyellow;
				background-color: #000;
				border: 2px solid $allureyellow;

				i.material-icons{
					color:$allureyellow;
					background-color: #000;
				}
			}

		}
	}

	.event-link:hover .event-image{
		transition: padding 100ms ease-in-out;
		padding:.6rem!important;
	}

	.event-image{
		display: block;
		height: 250px;
		background-color: $allureyellow;
	}

	.event-button{
		position: absolute;
		right:10px;
		bottom:-10px;
		display: flex;
	}
}


/* TEAMMEMBERS */
$n: 100;
.teammembers{

	position: relative;
	margin-bottom:-50%;

	.teammember{
		position: relative;
		width:23%;
		height:250px;
		margin:1%;
		margin-top:-35px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		background-blend-mode: luminosity;

		clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 82%);
		-webkit-clip-path: polygon(0 0, 100% 18%, 100% 100%, 0 82%);

		&.zwart{
			background-color: rgba(0, 0, 0, 1);
		}
		&.geel{
			background-color: rgba(255, 193, 7, 1);
		}

		//set --index
		@for $i from 1 through $n {
			&:nth-child(#{$i}) {
				--index: #{$i - 1};
			}
		}

		&:nth-child(4n-3) {
			top:-148px;
		}
		&:nth-child(4n-2) {
			top:-98px;
		}
		&:nth-child(4n-1) {
			top:-48px;
		}
	}
}

@media(min-width:768px){
	.teammembers{
		position: relative;
		margin-bottom:-100%;
	}
}



// Cookies
#cookies {
	position: fixed;
	z-index: 10000;
	bottom: 50px;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
}
.cookies {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	margin: 0 1rem;
	background-color: $softorange;
	color: $primary;
	#cookies-accept.btn-primary {
		border: 1px solid transparent;
	}
	#cookies-refuse.btn-outline-primary {
		color: $orange;
		border: 1px solid transparent;
		&:hover, &:focus {
			border: 1px solid #fff !important;
		}
	}
	strong {
		font-size: 1.2em;
		color: $primary;
	}
}
.text-primary {
	color: $primary  !important;
}
.radio-label .form-group > label {
	color: rgba(0, 0, 0, 0.38);
	display: block;
	font-size: 1rem;
	line-height: 1.5;
	margin: 0;
	padding: 0;
}
.mt-0-cascade * {
	margin-top: 0 !important;
}
.mb-0-cascade * {
	margin-bottom: 0 !important;
}
@media (max-width:992px) {}
@media only screen and (max-width:650px) {
	.cookies {
		flex-direction: column;
		strong {
			font-size: 1.1em;
		}
		> div:first-of-type {
			margin-bottom: 1rem;
		}
	}
}
@media only screen and (max-width:475px) {}
@media only screen and (max-width:400px) {
	.cookies {
		strong {
			font-size: 1em;
		}
		> div:first-of-type {
			flex-direction: column;
		}
	}
}

.card {
	border-radius: 0px;
	box-shadow:none;
}

/* CARD DIAGONALS */
.card-diagonal-box-bottom {
	position: relative;
	margin-bottom: calc(var(--tan-21) * calc(100%));

	&::after{
		content:'';
		position:absolute;
		z-index: 1;
		bottom: calc(((var(--tan-21) * calc(100%)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * calc(100%));
		background-color: inherit;

		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
	}
}

.card-diagonal-box-top{
	position: relative;

	&::before{
		content:'';
		position:absolute;
		z-index: 1;
		top: calc(((var(--tan-21) * calc(100%)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * calc(100%));
		background-color: inherit;

		clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
	}

	.container{
		margin-top:-15vw;
	}
}

.card-diagonal-box-top-bottom {
	position: relative;

	&::after{
		content:'';
		position:absolute;
		z-index: 1;
		bottom: calc(((var(--tan-21) * calc(100%)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * calc(100%));
		background-color: inherit;

		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
		-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 0);
	}

	&::before{
		content:'';
		position:absolute;
		z-index: 1;
		top: calc(((var(--tan-21) * calc(100%)) - 0.2px) * -1);
		left:0;
		display:block;
		width:100%;
		height:calc(var(--tan-21) * calc(100%));
		background-color: inherit;

		clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
		-webkit-clip-path: polygon(0 0, 0 0, 100% 100%, 0 100%);
	}

	.container{
		margin-top:-15vw;
	}
}

.img-shadow {
	border-radius: 12px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.img-circle {
	clip-path: circle();
}
.img-circle-shadow {
	filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.15));
}


.img-thumbnail {
	border: 1px solid rgba($primary, 0.15);
}


.bg-pos-left {
	background-position: left !important;
}
.bg-pos-right {
	background-position: right !important;
}
.bg-pos-middle {
	background-position: center !important;
}

.dz-image {
	overflow: hidden;
	white-space: nowrap;
}
.attachment-icon {
	font-size: 5rem;
	color: #476580;
	line-height: 80px;
}
.attachment-title {
	margin: 0 5px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.pointer-events-none {
	pointer-events: none;
}
.border-radius-50 {
	border-radius: 50px !important;
}
.material-icons {
	&.icon-left {
		margin-left: -17.5px;
		margin-right: 10px;
	}
	&.icon-right {
		margin-right: -17.5px;
		margin-left: 10px;
	}
}

.search-form {
	form {
		display: inline;
	}
	.search-container {
		position: relative;
	}
	input {
		outline: 0;
		border: 0;
		border-radius: 50px;
		border: 1px solid $orange;
		transition: $transition;
		text-align: left;
		padding: 0 10px;
		height: 31px;
		width: 45px;
		padding-right: 0;
		&:focus, &:active, &:hover, &.has-value {
			border: 1px solid $orange  !important;
			background-color: #fff !important;
			color: #333;
			width: 180px;
			padding-right: 35px;
			& + .material-icons {
				color: $orange;
				right: 2px;
			}
		}
	}
	.material-icons {
		position: absolute;
		top: 2px;
		height: 27px;
		width: 27px;
		line-height: 27px;
		right: 9px;
		color: #fff;
		transition-duration: 0.2s;
		pointer-events: none;
		padding: 0;
		background-color: transparent;
		border: 0;
		border-radius: 50%;
		&.allow-submit {
			pointer-events: initial;
			cursor: pointer;
		}
		&:focus, &:active, &:hover {
			outline: 0;
			border: 0;
			background-color: $orange;
			color: #fff !important;
		}
	}
}

#overview-menu {
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;
	pointer-events: none;
	width: 100%;
	max-width: 1800px;
	margin: 0 auto;
	.card-container {
		pointer-events: all;
		width: 300px;
		margin: 0 auto 0 0;
		position: sticky;
		top: 100px;
		left: 20px;
		z-index: 9;
		span.menu-toggle {
			display: none;
		}
		.card::before {
			content: "";
			position: absolute;
			display: block;
			top: 0;
			right: 0;
			height: 275px;
			width: 500px;
			z-index: -435789;
			background-image: url('../img/lines.png');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
		}
		.card-body {
			position: relative;
			z-index: 9;
		}
		a {
			padding: 0;
			border: 0;
			font-size: 1.23em;
			display: block;
			font-weight: 700;
			padding: 3px 5px;
			color: $orange;
			transition-duration: 0.2s;
			border-radius: 8px;
			&:not(:first-of-type) {
				margin-top: 5px;
			}
			&:hover, &:active, &:focus {
				color: #fff;
				background-color: $orange;
			}
			&.active {
				background-color: $orange;
				color: #fff;
			}
		}
	}
}
.tag {
	background-color: $orange;
	display: inline-block;
	color: #fff;
	font-weight: 700;
	font-size: 1.5rem;
	padding: 0 20px;
	border-radius: 8px;
	margin-bottom: 1rem;
}

// To admin button
.to-admin {
	background-color: #ee7e1c;
	position: fixed;
	width: 40px;
	margin: auto;
	bottom: 0;
	right: 0;
	z-index: 10000;
	&.is-quick {
		bottom: 8px;
		right: 8px;
	}
	a {
		user-select: none !important;
		color: #fff !important;
		width: 40px;
		height: 40px;
		display: block;
		text-align: center;
		&.disabled {
			color: rgba(255, 255, 255, 0.5) !important;
			pointer-events: none;
		}
	}
	a.clickable {
		background-color: $wizardgreen;
	}
	span {
		line-height: 40px;
		font-size: 2rem;
		transition: $transition;
	}
	.active {
		color: $blue  !important;
	}
}
.seo-warning {
	position: fixed;
	height: 40px;
	width: auto;
	bottom: 0;
	right: 40px;
	background-color: #D33C44;
	padding: 0 10px;
	z-index: 10000;
	p {
		line-height: 40px;
		font-weight: 700;
		color: #fff;
	}
}

//
// Media
//
@media only screen and (max-width: 1799px) {
	#overview-menu {
		.card-container {
			left: 0;
		}
		.card {
			width: 100%;
			max-width: 75vw;
			position: absolute;
			top: 20px;
			left: -350px;
			transition: $transition;
			&.display {
				left: 20px !important;
			}
			span.menu-toggle {
				display: block;
				position: absolute;
				top: 1rem;
				right: -41.44px;
				// 41.44px being the height of a list item in the menu
				width: 41.44px;
				height: 41.44px;
				border-top-right-radius: 12px;
				border-bottom-right-radius: 12px;
				background-color: $orange;
				box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
				cursor: pointer;
				.material-icons {
					color: #fff;
					width: 41.44px;
					height: 41.44px;
					line-height: 41.44px;
					font-size: 38px;
				}
			}
		}
	}
}

@media only screen and (min-width: 1000px) {
	.bg-small-header .container {
		max-width: 1000px !important;
	}
}
@media only screen and (max-width: 992px) {
	h1 {
		font-size: 3rem !important;
	}
}
@media only screen and (max-width: 700px) {
	h1 {
		font-size: 2.4rem !important;
	}
	h2 {
		font-size: 1.9rem !important;
	}
	h3 {
		font-size: 1.6rem !important;
	}
	h4 {
		font-size: 1.15rem !important;
	}
	.container-fluid {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@media only screen and (max-width: 500px) {
	h1 {
		font-size: 2rem !important;
	}
	h2 {
		font-size: 1.6rem !important;
	}
	h3 {
		font-size: 1.4rem !important;
	}
	h4 {
		font-size: 1.1rem !important;
	}
	.container-fluid {
		padding-left: 5px;
		padding-right: 5px;
	}
}