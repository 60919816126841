.svg-icon{
	position: absolute;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	opacity: .5;
	width:150px;
	height:150px;
}

.svg-icon-before{
	top: -30px;
    left: -75px;
}

.svg-icon-after{
	top: -30px;
    right: -75px;
}